import React from "react";
import theme from "theme";
import { Theme, Image, Link, Section, Text, Box, Icon, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";
import { AiFillLinkedin } from "react-icons/ai";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"team"} />
		<Helmet>
			<title>
				Team | Humans for AI
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:title"} content={"Team | Humans for AI"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI%20Favicon.png?v=2024-02-10T08:45:31.376Z"} type={"image/x-icon"} />
		</Helmet>
		<Section quarkly-title="Header" align-items="center" justify-content="center" padding="16px 0 16px 0">
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
			/>
			<Link href="/" position="relative" transition="opacity 200ms ease" quarkly-title="Link">
				<Image
					src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14:58:12.720Z"
					width="120px"
					z-index="3"
					srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/HFAI_logo_125x100.png?v=2023-04-12T14%3A58%3A12.720Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Link>
			<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end" margin="35px 0px 0px 0px">
				<Override
					slot="icon"
					icon={MdMenu}
					size="36px"
					md-right="0px"
					md-position="relative"
					category="md"
				/>
				<Override
					slot="menu-open"
					md-justify-content="center"
					md-top={0}
					md-bottom={0}
					md-display="flex"
					md-flex-direction="column"
					md-align-items="center"
				/>
				<Override slot="icon-open" md-right="18px" md-position="fixed" md-top="18px" />
				<Override
					slot="menu"
					md-height="100%"
					justify-content="center"
					lg-transition="transform 400ms ease 0s"
					md-position="fixed"
					display="flex"
					md-top={0}
					md-width="100%"
					lg-transform="translateY(0px) translateX(0px)"
					md-left={0}
					padding="0px 0 0px 0"
					align-items="center"
					filterPages="/aisc-davis"
				>
					<Override slot="item-index" lg-display="none" display="none" />
					<Override
						slot="link"
						hover-opacity="1"
						md-transition="opacity .15s ease 0s"
						md-font="16px/24px sans-serif"
						font="--base"
						color="--dark"
						opacity=".5"
						transition="opacity .15s ease 0s"
						text-transform="initial"
						md-color="--dark"
						letter-spacing="0.5px"
						md-opacity=".5"
						md-hover-opacity="1"
						md-active-opacity="1"
						text-decoration-line="initial"
					/>
					<Override
						slot="link-active"
						opacity="1"
						color="--primary"
						cursor="default"
						md-opacity="1"
						md-cursor="default"
					/>
					<Override
						slot="item"
						text-align="center"
						padding="8px 20px 8px 20px"
						md-padding="16px 40px 16px 40px"
						display="inline-block"
						text-transform="uppercase"
					/>
					<Override slot="item-404" lg-display="none" display="none" />
					<Override slot="link-work">
						AI Student Collective
					</Override>
				</Override>
				<Override slot="icon,icon-close" category="md" icon={MdMenu} />
			</Components.BurgerMenu>
		</Section>
		<Section
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
			padding="80px 0px 60px 5%px"
			quarkly-title="Team"
			box-sizing="border-box"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Text
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				color="--dark"
				text-transform="uppercase"
				text-align="center"
				font="--base"
				opacity="0.5"
			>
				Meet Us
			</Text>
			<Text
				color="--dark"
				md-margin="0px 0px 32px 0px"
				font="--headline2"
				letter-spacing="1px"
				width="100%"
				lg-margin="0px 0px 42px 0px"
				md-font="--headline3"
				margin="0px 0px 72px 0px"
				text-align="center"
			>
				Executive Team
			</Text>
			<Box
				sm-grid-template-columns="1fr"
				min-width="10px"
				display="grid"
				width="100%"
				grid-gap="32px"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 0px 32px 0px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Beena-Ammanath2_260x260.png?v=2023-09-04T23:57:30.812Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Beena-Ammanath2_260x260.png?v=2023-09-04T23%3A57%3A30.812Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Beena-Ammanath2_260x260.png?v=2023-09-04T23%3A57%3A30.812Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Beena-Ammanath2_260x260.png?v=2023-09-04T23%3A57%3A30.812Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Beena-Ammanath2_260x260.png?v=2023-09-04T23%3A57%3A30.812Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Beena-Ammanath2_260x260.png?v=2023-09-04T23%3A57%3A30.812Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Beena-Ammanath2_260x260.png?v=2023-09-04T23%3A57%3A30.812Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Beena-Ammanath2_260x260.png?v=2023-09-04T23%3A57%3A30.812Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-margin="12px 0px 0px 0px" sm-width="100%">
						<Text
							font="--headline3"
							margin="24px 0px 8px 0px"
							text-align="left"
							lg-margin="12px 0px 8px 0px"
							md-margin="8px 0px 2px 0px"
							color="--dark"
							quarkly-title="Name"
							sm-margin="0px 0px 4px 0px"
							md-font="--lead"
						>
							Beena Ammanath
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							text-align="left"
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
							font="--base"
						>
							Founder
						</Text>
					</Box>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Rachel_Trombetta_260x260.jpg?v=2023-09-04T23:59:06.972Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Rachel_Trombetta_260x260.jpg?v=2023-09-04T23%3A59%3A06.972Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Rachel_Trombetta_260x260.jpg?v=2023-09-04T23%3A59%3A06.972Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Rachel_Trombetta_260x260.jpg?v=2023-09-04T23%3A59%3A06.972Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Rachel_Trombetta_260x260.jpg?v=2023-09-04T23%3A59%3A06.972Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Rachel_Trombetta_260x260.jpg?v=2023-09-04T23%3A59%3A06.972Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Rachel_Trombetta_260x260.jpg?v=2023-09-04T23%3A59%3A06.972Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Rachel_Trombetta_260x260.jpg?v=2023-09-04T23%3A59%3A06.972Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-width="100%" sm-margin="12px 0px 0px 0px">
						<Text
							font="--headline3"
							margin="24px 0px 8px 0px"
							text-align="left"
							quarkly-title="Name"
							md-font="--lead"
							color="--dark"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							md-margin="8px 0px 2px 0px"
						>
							Rachel Trombetta
						</Text>
						<Text
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
							font="--base"
							margin="0px 0px 0px 0px"
							text-align="left"
						>
							Co-Chief Executive Officer
						</Text>
					</Box>
				</Box>
				<Box flex-direction="column" align-items="flex-start" display="flex">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Tarun%20Rishi_260x260.jpg?v=2023-09-05T00:00:10.955Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Tarun%20Rishi_260x260.jpg?v=2023-09-05T00%3A00%3A10.955Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Tarun%20Rishi_260x260.jpg?v=2023-09-05T00%3A00%3A10.955Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Tarun%20Rishi_260x260.jpg?v=2023-09-05T00%3A00%3A10.955Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Tarun%20Rishi_260x260.jpg?v=2023-09-05T00%3A00%3A10.955Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Tarun%20Rishi_260x260.jpg?v=2023-09-05T00%3A00%3A10.955Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Tarun%20Rishi_260x260.jpg?v=2023-09-05T00%3A00%3A10.955Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Tarun%20Rishi_260x260.jpg?v=2023-09-05T00%3A00%3A10.955Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-width="100%" sm-margin="12px 0px 0px 0px">
						<Text
							margin="24px 0px 8px 0px"
							quarkly-title="Name"
							md-font="--lead"
							text-align="left"
							font="--headline3"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							md-margin="8px 0px 2px 0px"
							color="--dark"
						>
							Tarun Rishi
						</Text>
						<Text
							color="--dark"
							quarkly-title="Position"
							text-align="left"
							font="--base"
							margin="0px 0px 0px 0px"
							opacity="0.6"
						>
							Co-Chief Executive Officer
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AneetJune2020.jpeg?v=2023-09-05T00:04:04.659Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AneetJune2020.jpeg?v=2023-09-05T00%3A04%3A04.659Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AneetJune2020.jpeg?v=2023-09-05T00%3A04%3A04.659Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AneetJune2020.jpeg?v=2023-09-05T00%3A04%3A04.659Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AneetJune2020.jpeg?v=2023-09-05T00%3A04%3A04.659Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AneetJune2020.jpeg?v=2023-09-05T00%3A04%3A04.659Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AneetJune2020.jpeg?v=2023-09-05T00%3A04%3A04.659Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AneetJune2020.jpeg?v=2023-09-05T00%3A04%3A04.659Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-margin="12px 0px 0px 0px" sm-width="100%">
						<Text
							color="--dark"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							font="--headline3"
							margin="24px 0px 8px 0px"
							text-align="left"
							quarkly-title="Name"
							md-margin="8px 0px 2px 0px"
							md-font="--lead"
						>
							Aneet Bhattal
						</Text>
						<Text
							font="--base"
							margin="0px 0px 0px 0px"
							text-align="left"
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
							md-margin="0px 0px 0px 0px"
						>
							Chief Operating Officer
						</Text>
					</Box>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Kenneth_Parker_260x260.jpeg?v=2023-09-05T03:27:44.178Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Kenneth_Parker_260x260.jpeg?v=2023-09-05T03%3A27%3A44.178Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Kenneth_Parker_260x260.jpeg?v=2023-09-05T03%3A27%3A44.178Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Kenneth_Parker_260x260.jpeg?v=2023-09-05T03%3A27%3A44.178Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Kenneth_Parker_260x260.jpeg?v=2023-09-05T03%3A27%3A44.178Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Kenneth_Parker_260x260.jpeg?v=2023-09-05T03%3A27%3A44.178Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Kenneth_Parker_260x260.jpeg?v=2023-09-05T03%3A27%3A44.178Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Kenneth_Parker_260x260.jpeg?v=2023-09-05T03%3A27%3A44.178Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-margin="12px 0px 0px 0px" sm-width="100%">
						<Text
							md-margin="8px 0px 2px 0px"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							quarkly-title="Name"
							margin="24px 0px 8px 0px"
							text-align="left"
							color="--dark"
							md-font="--lead"
							font="--headline3"
						>
							Kenneth Parker
						</Text>
						<Text
							font="--base"
							margin="0px 0px 0px 0px"
							text-align="left"
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
							md-margin="0px 0px 0px 0px"
						>
							Chief Business Officer
						</Text>
					</Box>
				</Box>
				<Box flex-direction="column" align-items="flex-start" display="flex">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1694460082950.jpeg?v=2023-11-21T13:09:04.597Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1694460082950.jpeg?v=2023-11-21T13%3A09%3A04.597Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1694460082950.jpeg?v=2023-11-21T13%3A09%3A04.597Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1694460082950.jpeg?v=2023-11-21T13%3A09%3A04.597Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1694460082950.jpeg?v=2023-11-21T13%3A09%3A04.597Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1694460082950.jpeg?v=2023-11-21T13%3A09%3A04.597Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1694460082950.jpeg?v=2023-11-21T13%3A09%3A04.597Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1694460082950.jpeg?v=2023-11-21T13%3A09%3A04.597Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-margin="12px 0px 0px 0px" sm-width="100%">
						<Text
							margin="24px 0px 8px 0px"
							md-margin="8px 0px 2px 0px"
							md-font="--lead"
							font="--headline3"
							color="--dark"
							quarkly-title="Name"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							text-align="left"
						>
							Morgan Stewart
						</Text>
						<Text
							font="--base"
							margin="0px 0px 0px 0px"
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
							md-margin="0px 0px 0px 0px"
						>
							Chief Marketing Officer
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Neil-Damle_260x260.jpeg?v=2023-09-05T03:34:26.243Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Neil-Damle_260x260.jpeg?v=2023-09-05T03%3A34%3A26.243Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Neil-Damle_260x260.jpeg?v=2023-09-05T03%3A34%3A26.243Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Neil-Damle_260x260.jpeg?v=2023-09-05T03%3A34%3A26.243Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Neil-Damle_260x260.jpeg?v=2023-09-05T03%3A34%3A26.243Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Neil-Damle_260x260.jpeg?v=2023-09-05T03%3A34%3A26.243Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Neil-Damle_260x260.jpeg?v=2023-09-05T03%3A34%3A26.243Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Neil-Damle_260x260.jpeg?v=2023-09-05T03%3A34%3A26.243Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-margin="12px 0px 0px 0px" sm-width="100%">
						<Text
							color="--dark"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							font="--headline3"
							margin="24px 0px 8px 0px"
							text-align="left"
							quarkly-title="Name"
							md-margin="8px 0px 2px 0px"
							md-font="--lead"
						>
							Neil Damle
						</Text>
						<Text
							font="--base"
							margin="0px 0px 0px 0px"
							text-align="left"
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
							md-margin="0px 0px 0px 0px"
						>
							Chief Technical Officer
						</Text>
					</Box>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AnirudhPhoto.jpg?v=2023-09-05T03:37:28.662Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AnirudhPhoto.jpg?v=2023-09-05T03%3A37%3A28.662Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AnirudhPhoto.jpg?v=2023-09-05T03%3A37%3A28.662Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AnirudhPhoto.jpg?v=2023-09-05T03%3A37%3A28.662Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AnirudhPhoto.jpg?v=2023-09-05T03%3A37%3A28.662Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AnirudhPhoto.jpg?v=2023-09-05T03%3A37%3A28.662Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AnirudhPhoto.jpg?v=2023-09-05T03%3A37%3A28.662Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/AnirudhPhoto.jpg?v=2023-09-05T03%3A37%3A28.662Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-width="100%" sm-margin="12px 0px 0px 0px">
						<Text
							font="--headline3"
							margin="24px 0px 8px 0px"
							text-align="left"
							quarkly-title="Name"
							md-font="--lead"
							color="--dark"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							md-margin="8px 0px 2px 0px"
						>
							Anirudh Murugesan
						</Text>
						<Text
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
							font="--base"
							margin="0px 0px 0px 0px"
							text-align="left"
						>
							Chief Innovation Officer
						</Text>
					</Box>
				</Box>
				<Box flex-direction="column" align-items="flex-start" display="flex">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Screen%20Shot%202023-11-21%20at%201.09.59%20PM.png?v=2023-11-21T13:10:17.053Z"
						display="block"
						width="100%"
						aspect-ratio="1/1"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Screen%20Shot%202023-11-21%20at%201.09.59%20PM.png?v=2023-11-21T13%3A10%3A17.053Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Screen%20Shot%202023-11-21%20at%201.09.59%20PM.png?v=2023-11-21T13%3A10%3A17.053Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Screen%20Shot%202023-11-21%20at%201.09.59%20PM.png?v=2023-11-21T13%3A10%3A17.053Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Screen%20Shot%202023-11-21%20at%201.09.59%20PM.png?v=2023-11-21T13%3A10%3A17.053Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Screen%20Shot%202023-11-21%20at%201.09.59%20PM.png?v=2023-11-21T13%3A10%3A17.053Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Screen%20Shot%202023-11-21%20at%201.09.59%20PM.png?v=2023-11-21T13%3A10%3A17.053Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Screen%20Shot%202023-11-21%20at%201.09.59%20PM.png?v=2023-11-21T13%3A10%3A17.053Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-width="100%" sm-margin="12px 0px 0px 0px">
						<Text
							margin="24px 0px 8px 0px"
							quarkly-title="Name"
							md-font="--lead"
							text-align="left"
							font="--headline3"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							md-margin="8px 0px 2px 0px"
							color="--dark"
						>
							Cheryl Etelvari
						</Text>
						<Text
							color="--dark"
							quarkly-title="Position"
							text-align="left"
							font="--base"
							margin="0px 0px 0px 0px"
							opacity="0.6"
						>
							Chief Editor
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Andromachi_260x260.jpeg?v=2023-09-05T03:36:51.338Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Andromachi_260x260.jpeg?v=2023-09-05T03%3A36%3A51.338Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Andromachi_260x260.jpeg?v=2023-09-05T03%3A36%3A51.338Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Andromachi_260x260.jpeg?v=2023-09-05T03%3A36%3A51.338Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Andromachi_260x260.jpeg?v=2023-09-05T03%3A36%3A51.338Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Andromachi_260x260.jpeg?v=2023-09-05T03%3A36%3A51.338Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Andromachi_260x260.jpeg?v=2023-09-05T03%3A36%3A51.338Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Andromachi_260x260.jpeg?v=2023-09-05T03%3A36%3A51.338Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-margin="12px 0px 0px 0px" sm-width="100%">
						<Text
							color="--dark"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							font="--headline3"
							margin="24px 0px 8px 0px"
							text-align="left"
							quarkly-title="Name"
							md-margin="8px 0px 2px 0px"
							md-font="--lead"
						>
							Andromachi Orfanidou
						</Text>
						<Text
							font="--base"
							margin="0px 0px 0px 0px"
							text-align="left"
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
							md-margin="0px 0px 0px 0px"
						>
							Head Of Global Engagement
						</Text>
					</Box>
				</Box>
			</Box>
			<Text
				color="--dark"
				md-margin="0px 0px 32px 0px"
				font="--headline2"
				letter-spacing="1px"
				width="100%"
				lg-margin="0px 0px 42px 0px"
				md-font="--headline3"
				margin="60px 0px 72px 0px"
				text-align="center"
			>
				Extended Team
			</Text>
			<Box
				sm-grid-template-columns="1fr"
				min-width="0px"
				display="grid"
				width="100%"
				grid-gap="32px"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1667482522112.jpeg?v=2023-11-20T20:54:54.336Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1667482522112.jpeg?v=2023-11-20T20%3A54%3A54.336Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1667482522112.jpeg?v=2023-11-20T20%3A54%3A54.336Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1667482522112.jpeg?v=2023-11-20T20%3A54%3A54.336Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1667482522112.jpeg?v=2023-11-20T20%3A54%3A54.336Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1667482522112.jpeg?v=2023-11-20T20%3A54%3A54.336Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1667482522112.jpeg?v=2023-11-20T20%3A54%3A54.336Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1667482522112.jpeg?v=2023-11-20T20%3A54%3A54.336Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-margin="12px 0px 0px 0px" sm-width="100%">
						<Text
							font="--headline3"
							margin="24px 0px 8px 0px"
							text-align="left"
							lg-margin="12px 0px 8px 0px"
							md-margin="8px 0px 2px 0px"
							color="--dark"
							quarkly-title="Name"
							sm-margin="0px 0px 4px 0px"
							md-font="--lead"
						>
							Tess Buckley
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							text-align="left"
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
							font="--base"
						>
							Periodic Contributor
						</Text>
					</Box>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1692487609111.jpeg?v=2023-11-20T20:54:54.329Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1692487609111.jpeg?v=2023-11-20T20%3A54%3A54.329Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1692487609111.jpeg?v=2023-11-20T20%3A54%3A54.329Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1692487609111.jpeg?v=2023-11-20T20%3A54%3A54.329Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1692487609111.jpeg?v=2023-11-20T20%3A54%3A54.329Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1692487609111.jpeg?v=2023-11-20T20%3A54%3A54.329Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1692487609111.jpeg?v=2023-11-20T20%3A54%3A54.329Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1692487609111.jpeg?v=2023-11-20T20%3A54%3A54.329Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-width="100%" sm-margin="12px 0px 0px 0px">
						<Text
							font="--headline3"
							margin="24px 0px 8px 0px"
							text-align="left"
							quarkly-title="Name"
							md-font="--lead"
							color="--dark"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							md-margin="8px 0px 2px 0px"
						>
							Sunita Uthra
						</Text>
						<Text
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
							font="--base"
							margin="0px 0px 0px 0px"
							text-align="left"
						>
							Volunteer
						</Text>
					</Box>
				</Box>
				<Box flex-direction="column" align-items="flex-start" display="flex">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1673901078385.jpeg?v=2023-11-20T20:54:54.335Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1673901078385.jpeg?v=2023-11-20T20%3A54%3A54.335Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1673901078385.jpeg?v=2023-11-20T20%3A54%3A54.335Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1673901078385.jpeg?v=2023-11-20T20%3A54%3A54.335Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1673901078385.jpeg?v=2023-11-20T20%3A54%3A54.335Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1673901078385.jpeg?v=2023-11-20T20%3A54%3A54.335Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1673901078385.jpeg?v=2023-11-20T20%3A54%3A54.335Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1673901078385.jpeg?v=2023-11-20T20%3A54%3A54.335Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-width="100%" sm-margin="12px 0px 0px 0px">
						<Text
							margin="24px 0px 8px 0px"
							quarkly-title="Name"
							md-font="--lead"
							text-align="left"
							font="--headline3"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							md-margin="8px 0px 2px 0px"
							color="--dark"
						>
							Luke Barrett
						</Text>
						<Text
							color="--dark"
							quarkly-title="Position"
							text-align="left"
							font="--base"
							margin="0px 0px 0px 0px"
							opacity="0.6"
						>
							Director of Operations
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1699539782549.jpeg?v=2023-11-20T20:54:54.362Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1699539782549.jpeg?v=2023-11-20T20%3A54%3A54.362Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1699539782549.jpeg?v=2023-11-20T20%3A54%3A54.362Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1699539782549.jpeg?v=2023-11-20T20%3A54%3A54.362Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1699539782549.jpeg?v=2023-11-20T20%3A54%3A54.362Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1699539782549.jpeg?v=2023-11-20T20%3A54%3A54.362Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1699539782549.jpeg?v=2023-11-20T20%3A54%3A54.362Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1699539782549.jpeg?v=2023-11-20T20%3A54%3A54.362Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-margin="12px 0px 0px 0px" sm-width="100%">
						<Text
							color="--dark"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							font="--headline3"
							margin="24px 0px 8px 0px"
							text-align="left"
							quarkly-title="Name"
							md-margin="8px 0px 2px 0px"
							md-font="--lead"
						>
							Ron Rel
						</Text>
						<Text
							font="--base"
							margin="0px 0px 0px 0px"
							text-align="left"
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
							md-margin="0px 0px 0px 0px"
						>
							Volunteer
						</Text>
					</Box>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" width="100%">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1696283876285.jpeg?v=2023-11-20T20:54:54.341Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1696283876285.jpeg?v=2023-11-20T20%3A54%3A54.341Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1696283876285.jpeg?v=2023-11-20T20%3A54%3A54.341Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1696283876285.jpeg?v=2023-11-20T20%3A54%3A54.341Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1696283876285.jpeg?v=2023-11-20T20%3A54%3A54.341Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1696283876285.jpeg?v=2023-11-20T20%3A54%3A54.341Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1696283876285.jpeg?v=2023-11-20T20%3A54%3A54.341Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1696283876285.jpeg?v=2023-11-20T20%3A54%3A54.341Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-margin="12px 0px 0px 0px" sm-width="100%">
						<Text
							md-margin="8px 0px 2px 0px"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							quarkly-title="Name"
							margin="24px 0px 8px 0px"
							text-align="left"
							color="--dark"
							md-font="--lead"
							font="--headline3"
						>
							Jasmine Sayed
						</Text>
						<Text
							font="--base"
							margin="0px 0px 0px 0px"
							text-align="left"
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
							md-margin="0px 0px 0px 0px"
						>
							Marketing Associate
						</Text>
					</Box>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column">
					<Image
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1676215623032.jpeg?v=2023-11-20T20:54:54.448Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1676215623032.jpeg?v=2023-11-20T20%3A54%3A54.448Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1676215623032.jpeg?v=2023-11-20T20%3A54%3A54.448Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1676215623032.jpeg?v=2023-11-20T20%3A54%3A54.448Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1676215623032.jpeg?v=2023-11-20T20%3A54%3A54.448Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1676215623032.jpeg?v=2023-11-20T20%3A54%3A54.448Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1676215623032.jpeg?v=2023-11-20T20%3A54%3A54.448Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/1676215623032.jpeg?v=2023-11-20T20%3A54%3A54.448Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box sm-margin="12px 0px 0px 0px" sm-width="100%">
						<Text
							md-margin="8px 0px 2px 0px"
							sm-margin="0px 0px 4px 0px"
							lg-margin="12px 0px 8px 0px"
							quarkly-title="Name"
							margin="24px 0px 8px 0px"
							text-align="left"
							color="--dark"
							md-font="--lead"
							font="--headline3"
						>
							Andrea Bowers
						</Text>
						<Text
							font="--base"
							margin="0px 0px 0px 0px"
							text-align="left"
							opacity="0.6"
							color="--dark"
							quarkly-title="Position"
							md-margin="0px 0px 0px 0px"
						>
							Head of Scholarships
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section>
			<Box min-width="100px" min-height="100px">
				<Text
					color="--dark"
					md-margin="0px 0px 32px 0px"
					font="--headline2"
					letter-spacing="1px"
					width="100%"
					lg-margin="0px 0px 42px 0px"
					md-font="--headline3"
					margin="60px 0px 72px 0px"
					text-align="center"
				>
					Join The Team!
				</Text>
				<Text
					color="--dark"
					md-margin="0px 0px 32px 0px"
					font="--lead"
					letter-spacing="1px"
					width="100%"
					lg-margin="0px 0px 42px 0px"
					md-font="--headline3"
					margin="60px 0px 72px 0px"
					text-align="center"
				>
					Feel free to email us at info@humansforai.com with your CV and an introduction.
				</Text>
			</Box>
		</Section>
		<Section
			margin="0 0 0 0"
			padding="16px 0 16px 0"
			box-sizing="border-box"
			quarkly-title="Footer"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				margin="0px 32px 0px 32px"
			/>
			<Box
				grid-gap="32px"
				width="100%"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-gap="16px"
				display="grid"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Image
						width="50px"
						height="50px"
						src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21:53:55.077Z"
						srcSet="https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6426e7e68d8a1a002089f81f/images/Humans-For-Ai_Logo%20%282%29.png?v=2023-09-07T21%3A53%3A55.077Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Link
						white-space="nowrap"
						font="--base"
						opacity="0.6"
						text-align="left"
						margin="1px 0px 0px 10px"
						color="--dark"
						text-decoration-line="initial"
						hover-text-decoration-line="underline"
						href="/index#"
					>
						Humans for AI
					</Link>
				</Box>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap" justify-content="flex-end">
					<LinkBox href="https://www.linkedin.com/company/humansforai/" target="_blank">
						<Icon
							category="ai"
							icon={AiFillLinkedin}
							size="64px"
							padding="0px 3% 0px 3%"
							width="50px"
							height="50px"
							pointer-events="auto"
						/>
					</LinkBox>
					<LinkBox href="https://medium.com/@humansforai" target="_blank">
						<Image src="https://uploads.quarkly.io/6426e7e68d8a1a002089f81f/images/icons8-medium-50.svg?v=2023-12-18T23:16:50.399Z" display="block" />
					</LinkBox>
				</Box>
			</Box>
		</Section>
	</Theme>;
});